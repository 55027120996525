import $ from 'jquery'
import { IMAGE_FILE_SIZE_LIMIT } from '../src/utils/constants'

export const dndContainerSelector = '[data-toggle="dnd-file-input"]'
const dndFileInputSelector = '[data-dnd-file-input="true"]'
const dndPreviewSelector = '[data-dnd-preview="true"]'

const fileTypes = [
  'image/jpeg',
  'image/pjpeg',
  'image/png'
]

const validateFileType = (file: File): boolean => {
  for (var i = 0; i < fileTypes.length; i++) {
    if (file.type === fileTypes[i]) {
      return true
    }
  }
  return false
}

const validateFileSize = (fileInput) => {
  const fileSize = fileInput.files[0].size
  const $errorMessage = $(fileInput).parents('.dnd-file-input-container').siblings('.error-messages')

  if (fileSize > IMAGE_FILE_SIZE_LIMIT) {
    $errorMessage.text('File size limit exceeded (' + bytesToSize(IMAGE_FILE_SIZE_LIMIT) + ' max)').show()
    return false
  }
  else {
    $errorMessage.text('').hide()
    return true
  }
}

function bytesToSize(bytes) {
   var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
   if (bytes == 0) return '0 Byte';
   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
   return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

const DndFileInput = ($container = null, $fileInput = null) => {
  const $dndInputContainers = $container || $(dndContainerSelector)
  const $dndFileInputs = $fileInput || $(dndFileInputSelector)
  const showPreview = (evt) => {
    const $preview = $(evt.target)
      .closest(dndContainerSelector)
      .find(dndPreviewSelector)
    const { files } = evt.target
    if (files &&
        $(evt.target).data('dnd-file-input') &&
        files.length > 0 &&
        validateFileType(files[0]) &&
        validateFileSize(evt.target)
    ) {
      const image = $('<img>').attr('src', URL.createObjectURL(files[0]))
      image.addClass(['img-fluid'])
      $preview.find('img').remove()
      if ($preview.find('.dnd-dropzone')) {
        $preview.find('.dnd-dropzone').remove()
        $preview.find('.file-input-icon').removeClass('d-none')
      }
      $preview.append(image)
    }
  }

  $dndInputContainers.click((e) => {
    const $dndFileInput = $(e.target)
      .closest('[data-toggle="dnd-file-input"]')
      .find('[data-dnd-file-input="true"]')
    $dndFileInput.click((e) => e.stopPropagation())
    $dndFileInput.click()
  })

  $dndFileInputs.each((_idx, input) => {
    $(input).attr('accept', 'image/*')

    if ($(input).prop('required')) {
      const $dndInputContainer = $(input).parents('.dnd-file-input-container')
      if ($dndInputContainer.find('img').length === 1)
        $(input).removeAttr('required')
      else
        $(input).parents('.dnd-file-input-container').siblings('.error-messages').text('Required.').show()
    }
  })

  $dndFileInputs.on('change', showPreview)
}

export default DndFileInput
