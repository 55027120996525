export const flatpickrActionPane = {
  create: (ops={}) => {
    const btnClasses = ['btn', 'btn-sm']
    const container = document.createElement('div')
    const clearBtn = document.createElement('button')
    let btns = []
    let containerClasses = ['flatpickr-actions']

    if (ops.confirm === true) {
      const saveBtn = document.createElement('button')
      saveBtn.innerHTML = 'confirm'
      saveBtn.classList.add(...btnClasses, 'btn-outline-success')
      btns.push(saveBtn)
    } else {
      containerClasses.push('justify-content-center')
    }

    clearBtn.innerHTML = 'clear'
    clearBtn.classList.add(...btnClasses, 'btn-outline-secondary')
    btns.push(clearBtn)
    container.classList.add(...containerClasses)
    container.append(...btns)
    return container
  }
}
