const TEXT_FIELD_TYPES = ['Title', 'Subtitle', 'Video']
const LISTING_TYPES = ['ListingAuto', 'ListingManual']
const CAROUSEL_TYPES = ['CarouselAuto', 'CarouselManual']
const PRODUCT_COLLECTION_TYPES = [
  ...CAROUSEL_TYPES,
  ...LISTING_TYPES
]

const LEVEL_OPTIONS = {
  PRODUCT: 'product',
  VARIANT: 'variant'
}

const LISTING_TYPE_OPTIONS = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic'
}

const VIDEO_TYPE_OPTIONS = {
  YOUTUBE: 'youtube',
  VUDOO: 'vudoo'
}

const STATUSES = ['Draft', 'Scheduled', 'Published', 'Archived']

const IMAGE_FILE_SIZE_LIMIT = 204800

export {
  TEXT_FIELD_TYPES,
  LISTING_TYPES,
  CAROUSEL_TYPES,
  PRODUCT_COLLECTION_TYPES,
  LEVEL_OPTIONS,
  LISTING_TYPE_OPTIONS,
  VIDEO_TYPE_OPTIONS,
  STATUSES,
  IMAGE_FILE_SIZE_LIMIT
}
