export const qAll = (query: String): NodeList<HTMLElement> => document.querySelectorAll(query)
export const q = (query: String): ?Node => document.querySelector(query)

export const formatSelectorData = (apiData) => {
  return apiData.map(data => {
    return {
      value: data.id,
      label: data.attributes ? data.attributes['name'] : data.name
    }
  })
}
