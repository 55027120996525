// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'bootstrap/dist/js/bootstrap'
import { qAll } from '../src/utils/js_helpers'
import flatpickr from 'flatpickr'
import { flatpickrActionPane } from '../src/libs/flatpickr-actions'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/sortable'
import 'selectize'
import DndFileInput from './dnd_file_input'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

let daterangePickrs // eslint-disable-line no-unused-vars
let articleVersionTabId = '#article-version-tab'

document.addEventListener('turbolinks:load', () => {
  let $articleVersionTab = $(articleVersionTabId)
  const dropdownTogglers = Array.from(qAll('.bulk-action-container'))
  if (dropdownTogglers.length > 0) {
    dropdownTogglers.forEach((toggler) => {
      toggler.addEventListener('click', (evt: Event) => {
        const { target } = evt
        if (
          target instanceof window.HTMLElement &&
          target.nextElementSibling
        ) {
          if (target.nextElementSibling.classList.contains('show')) {
            target.nextElementSibling.classList.remove('show')
          } else {
            target.nextElementSibling.classList.add('show')
          }
          target.blur()
        }
      })
    })
  }

  $('.selectize').selectize(
    {
      create: false,
      sortField: {
        field: 'text',
        direction: 'asc'
      },
      dropdownParent: 'body'
    }
  )

  if ($articleVersionTab.length > 0) {
    $articleVersionTab.find('li:first-child a').tab('show')
  }

  const daterangePicker = qAll('.daterange')
  if (daterangePicker.length > 0) {
    daterangePickrs = Array.from(daterangePicker).map(picker => flatpickr(picker, {
      altInput: true,
      altFormat: 'j F Y h:i K',
      enableTime: true,
      allowInput:true,
      onReady: (_, __, instance) => {
        const container = instance.calendarContainer
        const actionPane = container.querySelector('.flatpickr-actions')

        if (!actionPane) {
          container.append(flatpickrActionPane.create())
          container.addEventListener('click', (evt) => {
            const {target} = evt
            if (target instanceof window.HTMLButtonElement) {
              if (target.innerHTML === 'confirm') {
                instance.close()
              } else {
                instance.clear()
              }
            }
          })
        }
      }
    }))
    // Clear flatpickr input if there is none related value. (BUG with the flatpickr lib on IE11)
    if (
      $('.flatpickr-input').val() === 'Select start date' ||
      $('.flatpickr-input').val() === 'Select end date'
    ) {
      $('.flatpickr-input').val('')
    }
  }

  $('#content-types').sortable({
    update: function(e) {
      const auth_token = encodeURIComponent($('[name="csrf-token"]')[0].content)
      $.ajax({
        type: 'PATCH',
        url: $(this).data('url') + "?&authenticity_token=" + auth_token,
        data: $(this).sortable('serialize')
      })
    }
  })

  // Show file preview
  DndFileInput()
})
